body {
  margin: 0;
  background-color: #3A1066;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%233a1066' stroke-width='66.7' stroke-opacity='0.05' %3E%3Ccircle fill='%231d7bad' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%231c77ad' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%231e73ad' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%23226fad' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%23276bac' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%232e66ab' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%233561aa' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%233c5ca8' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%234357a5' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%234b52a2' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%23514c9e' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%2358469a' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%235f4095' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%2365398f' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%236a3189' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%236f2883' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%23741e7c' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23780f74' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  }

main {
  margin-top: 80px;
  font-family: 'Biryani', sans-serif;
  color: rgb(45, 86, 122);
  letter-spacing: -0.9px;
  display: flex;
  flex-direction: column;
  padding-bottom: 6.5rem;
}

a {
  text-decoration: none;
}

h1 {
  font-weight: 700;
 }

.bkg-div {
  background-color: rgba(249, 249, 249, 0.45);
  width: 80vw;
  margin: 20px 0;
  padding: 40px 40px;
}

.bkg-div > h1, h2 {
  margin: 0;
}

.center-div {
  align-self: center;
  border-radius: 25px;
}


/* nav */

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color:  rgba(58,16,102, 0.8);
  z-index: 3;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 600;
  font-size: 2em;
}

nav a {
  color: #F9F9F9;
  text-decoration: none;
  margin: 0 24px;
}

.nav-right > .active {
  text-decoration: underline;
}

/* home */

.home-main {
  justify-content: space-between;
 }

.right-div {
  align-self: flex-end;
  border-radius: 25px 0px 0px 25px;
}

.left-div {
  border-radius: 0 25px 25px 0;
}

.social-icons > a {
  color: rgb(45, 86, 122);
}

.fab, .fas {
 margin: 0.3em;
 font-size: 3em;
}

#profile-pic{
  border-radius: 100%;
  width: 300px;
  height: 300px;
 }

.home-about {
   display: flex;
   justify-content: space-around;
   align-items: center;
}
 
.profile-info {
   padding: 0 2em;
}

.project-thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.project-thumbnail > a, .project-thumbnail {
   max-width: 25em;
   margin: 2em;
}

.project-thumbnail:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}

.resume-button {
  font-family: '', sans-serif;
  font-weight: 700;
  letter-spacing: -0.9px;
  font-size: 1.1em;
  background-color: rgb(55, 93, 167);
  color: rgb(249, 249, 249);
  border-width: 0;
  padding: 8px 12px;
  border-radius: 10px;
}

 /* About */

.about-main > div {
  max-width: 900px;
}

 #about-pic {
  border-radius: 25px;
  width: 100%;
  object-fit: cover;
  max-width: 900px;
 }

 th, td {
   width: 50em;
 }

 /* contact */

 form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
 }

 input, textarea {
   font-size: 1.2em;
   font-family: 'Biryani', sans-serif;
   margin-bottom: 0.5em;
 }

 input {
  margin-bottom: 0.5em;
}

 textarea {
  height: 6em;
 }

 form > button {
   height: 2em;
   background-color: #F9F9F9;
   font-size: 1.2em;
   font-family: 'Biryani', sans-serif;
 }

 form > button:hover {
   background-color: rgb(45, 86, 122);
   color: #F9F9F9;
 }

 .required {
   color: red;
 }

 #gotcha {
   display: none;
 }

 /* projects */
 
.head-container {
  border-radius: 25px;
  text-align: center;
}

.projects-main {
  margin: 80px 40px 0 40px ;
}

#repos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#github-link > h1 {
  margin: 0;
}

.github-card {
  background-color: rgba(249, 249, 249, 0.45);
  width: 14em;
  height: 25vh;
  margin: 20px;
  padding: 10px 40px 60px 40px;
  border-radius: 25px;
}

.github-card:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}

.github-card > p {
  color: rgb(45, 86, 122)
}

footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5rem; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:  rgba(35, 10, 63, 0.8);
  z-index: 3;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 600;
  color: #F9F9F9;
}

.footer-links > a, #footer-resume{
  color: #F9F9F9;
  text-decoration: none;
}


footer > p {
  margin: 0;
}

@media only screen and (max-width: 750px) {

  .right-div, .left-div {
    align-self: center;
    border-radius: 25px;
    margin: 1rem 3rem;
  }

  .home-about {
    display: flex;
    flex-direction: column;
  }

  .profile-info > h2 {
    margin: 1em 0;
  }

  #about-pic {
    max-width: 300px;
  }

  .project-thumbnail > a, .project-thumbnail {
    max-width: 20em;
    margin: 2em;
  }
  nav a {
    font-size: 0.8em;
    margin: 0 10px;
  }
}